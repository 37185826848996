<template>
  <el-form inline :model="selectModel" label-position="right">
    <el-form-item label="名称">
      <el-input v-model="selectModel.name" placeholder="名称" />
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="selectModel.status" placeholder="请选择" clearable>
        <el-option v-for="(a,b) in $store.state.rechargeTemplateStatus" :key="b" :value="b" :label="a" />
      </el-select>
    </el-form-item>
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialogForm({created:true})">新建</el-button>
      </el-button-group>
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="100px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="名称" prop="name" min-width="200px" />
    <el-table-column label="状态" prop="status" min-width="80px">
      <template #default="scope">
        {{ $store.getters.columnIdToVal('rechargeTemplateStatus', scope) }}
      </template>
    </el-table-column>
    <el-table-column
      fixed="right"
      label="操作"
      width="120">
      <template #default="scope">
        <el-button @click="showDialogForm({data: scope.row, readonly: true})" type="text" size="small">详情</el-button>
        <el-button v-if="scope.row.status === 1" @click="showDialogForm({data: scope.row})" type="text" size="small">编辑</el-button>
        <el-button v-if="scope.row.status === 1" @click="upAciton(scope.row)" type="text" size="small">上线</el-button>
        <el-button v-if="scope.row.status === 2" @click="downAciton(scope.row)" type="text" size="small">下线</el-button>
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item label="类型">
        <el-select :model-value="1">
          <el-option label="充值赠送" :value="1" :disabled="false" />
          <el-option label="打折优惠" :value="2" :disabled="true" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="名称" prop="name"
        :rules="[
          { required: true, message: '名称不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.name" :readonly="dialog.readonly" />
      </el-form-item>

      <el-form-item
        v-for="(item, index) in dialog.form.params"
        :label="'参数' + (index + 1)"
        style="margin-bottom: 0;"
        :key="item">
        <span>充值</span> <el-input-number v-model="item.amount" size="mini" :precision="2" :step="0.1" :max="1000" :disabled="dialog.readonly" />
        &nbsp;&nbsp;<span>赠送</span> <el-input-number v-model="item.give" size="mini" :precision="2" :step="0.1" :max="10" :disabled="dialog.readonly" />
        &nbsp;&nbsp;<el-button v-if="!dialog.readonly" @click.prevent="delParam(index)" type="danger" size="mini" icon="el-icon-delete" circle />
      </el-form-item>
      <el-form-item v-if="!dialog.readonly">
        <el-button @click="addParam" type="primary" size="mini" icon="el-icon-plus" circle />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      params: []
    };
  },
  created() {
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getRechargePage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        })
        .catch(handleCatchStatus);
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    showDialogForm({data, created = false, readonly = false}) {
      this.dialog.readonly = readonly;
      this.dialog.created = created;
      this.dialog.visibled = true;
      if (data) {
        this.dialog.form = { ...data };
        this.$api.getRechargeParams(data.id).then(data => {
          data.forEach(element => {
            element.amount = Number(element.amount);
            element.give = Number(element.give);
          });
          this.dialog.form.params = data;
        });
      }

      let submit;
      if (created) {
        this.dialog.title = '创建';
        this.dialog.form = {};
        this.dialog.form.params = [{amount: 0, give: 0}];
        submit = () => this.$api.createRecharge(this.dialog.form);
      } else {
        this.dialog.title = '修改';
        submit = () => this.$api.updateRecharge(this.dialog.form);
      }

      if (readonly) {
        this.dialog.title = '详情';
        submit = () => false;
      }

      this.dialog.submit = () => this.validateForm(() => submit()
        .then(() => {
          this.$message.success('操作成功');
          this.dialog.visibled = false;
          this.queryTableData();
        })
        .catch(handleCatchStatus));
    },
    validateForm(successFn) {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          successFn();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dialogForm.resetFields();
    },
    upAciton(data) {
      this.$confirm('此操作将上线该模版, 为保证数据的一致性，上线后将不支持修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.updateRechargeStatus(data.id, 2))
        .then(() => {
          this.$message.success('操作成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消上线'));
    },
    downAciton(data) {
      this.$confirm('此操作将下线该模版, 下线后不能修改与使用, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.updateRechargeStatus(data.id, 3))
        .then(() => {
          this.$message.success('操作成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消下线'));
    },
    addParam() {
      this.dialog.form.params.push({amount: 0, give: 0});
    },
    delParam(index) {
      this.dialog.form.params.splice(index, 1);
    }
  }
};
</script>
